<template>

    <div class="h-auto rounded-lg bg-filters mb-2 py-1 flex flex-col" @click="selectOportunity()">

        <div class="h-6 p-2 flex flex-row justify-start items-center">
            <span class="text-xs mr-2 font-semibold" :class="percentageColor(opportunity.probability)">{{ opportunity.probability }}%</span>
            <span class="text-xs text-white font-semibold mr-auto truncate">{{ opportunity.opportunity_name }}</span>
        </div>

        <div class="flex-1 min-h-0">

            <div class="h-auto flex flex-row flex-none px-2">

                <div class="h-full w-2/3 flex flex-row justify-start items-center">
                    <span class="text-font-gray font-semibold text-3xs truncate-2">{{ this.opportunity.client_name }}</span>
                </div>
                <div class="h-full w-2"></div>
                <div class="h-full w-1/3 flex flex-row justify-end items-center">
                    <span class="text-aux text-right font-semibold  text-3xs truncate-2">{{ this.opportunity.competency || 'N/A' }}</span>
                </div>

            </div>

            <div class="h-auto py-2 flex flex-row justify-between items-center px-2">

                <span class="text-3xs text-font-gray">{{ opportunity.ep_name }}</span>

                <span class="text-3xs text-white">{{opportunity.sector_ambiciona}}</span>

            </div>

            <div class="h-auto py-2 flex flex-row justify-start items-center px-2">
                <span class="text-3xs text-font-gray mr-2">Opportunity Value</span>
                <span class="text-3xs text-white font-semibold mr-auto">{{ opportunity.valor_precio | reduceBigNumbers(2) }}€</span>
                <span class="text-3xs text-font-gray mr-2">Margin</span>
                <span class="text-3xs text-white font-semibold">{{ opportunity.margen | numberFormat(2) }}%</span>
            </div>

        </div>

    </div>

</template>

<script>
export default {
    name: 'OpportunityMobile',
    props: ['opportunity', 'sectors'],
    computed: {
        legendColors() { return this.$legendColorsBySector(this.sectors) }
    },
    methods:{
        percentageColor(percentage){

            switch (percentage) {
                case '25':
                    return 'text-percentage-25'
                    break;
                case '50':
                    return 'text-percentage-50'
                    break;
                case '75':
                    return 'text-percentage-75'
                    break;
                case '100':
                    return 'text-percentage-100'
                    break;
            }

        },
        selectOportunity(){
            this.$emit('selectOportunity', this.opportunity)
        }
    }
}
</script>